import './WineSuggestions.css'
import glass from '../../assets/wine-glass.png'
import bottle from '../../assets/wine-bottle.png'
import Wine from '../Wine/Wine'
import { motion } from 'framer-motion'

const WineSuggestions = ( {wine} ) => {
    return (
        <motion.div className='winesFlex' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} >
            <motion.h2 animate={{opacity: [0, 1], scale: [1.1, 1]}} transition={{duration: 0.4, delay: 0.4}} className='subtitleMenu subtitleMenu576px'>I vini del territorio <br/> <span className='italy'>per esaltare i nostri poké</span></motion.h2>
            <motion.div animate={{opacity: [0, 1], y: [50, 0], scale: [0.95, 1]}} transition={{duration: 0.4, delay: 0.4}} className='winesContainer'>
                <div className='wineIcon'>
                    <img src={glass} alt="Bicchiere" />
                    <img src={bottle} alt="Bottiglia" />
                </div>
                {wine.map( wine => <Wine key={wine.id} {...wine}/>)}
            </motion.div>
        </motion.div>
    )
}

export default WineSuggestions