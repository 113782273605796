import './App.css';
import Navbar from './components/Navbar/Navbar';
import Presentation from './components/Presentation/Presentation';
import WhereWeAre from './components/WhereWeAre/WhereWeAre';
import MenuContainer from './components/MenuContainer/MenuContainer';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useRef } from 'react';
/* import MakeYourPokeContainer from './components/MakeYourPokeContainer/MakeYourPokeContainer'; */

function App() {

  const refHome = useRef(null)
  const refDoveSiamo = useRef(null)
  const refLaNostraStoria = useRef(null)
  const refMenu = useRef(null)

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar refDoveSiamo={refDoveSiamo} refMenu={refMenu} refLaNostraStoria={refLaNostraStoria} refHome={refHome}/>
        <ScrollToTop/>
        <Presentation refHome={refHome}/>
        <WhereWeAre refDoveSiamo={refDoveSiamo}/>
        <Routes>
          <Route path='/' element={<MenuContainer refMenu={refMenu}/>}/>
          <Route path='/menu/:menuId' element={<MenuContainer refMenu={refMenu}/>}/>
          {/* <Route path='/make-your-poke' element={<MakeYourPokeContainer/>}/> */}
        </Routes>
        <WhoWeAre refLaNostraStoria={refLaNostraStoria}/>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
