import './Bevande.css'

const Bevande = ({id, name, price}) => {
    return(
        <div className="bevande">
            <p>{name}</p>
            <p>{price}</p>
        </div>
    )
}

export default Bevande