import './WhereWeAre.css'
import location from '../../assets/location.png'
import piazzaLuni from '../../assets/piazzaLuni.jpg'
import pokeHub from '../../assets/pokeHub.jpg'
import tavoli from '../../assets/tavoliPiazzaLuni.jpg'
import deliveroo from '../../assets/logoDeliveroo.png'
import coalvi from '../../assets/coalvi.png'
import agroalimentare from '../../assets/agroalimentare.png'
import toscopesce from '../../assets/toscopesce.png'
import lunensis from '../../assets/lunensis.png'
import { redirect } from '../../functions/Functions'
import { motion } from 'framer-motion'

const WhereWeAre = ({refDoveSiamo}) => {

    return(
        <section className='whereWeAre' ref={refDoveSiamo}>
            <div className='whereWeAreFlex'>
                <motion.h1 whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whereWeAreTitle'>Dove siamo</motion.h1>
                <motion.p whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whereWeAreText'>Siamo in Piazza Luni, nel centro storico di Sarzana. <br/> Il nostro locale offre posti a sedere per mangiare<br/> sia al chiuso sia all'aperto in piazza</motion.p>
                <motion.div whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}}  className='whereWeAreLocation'>
                    <motion.img onClick={() => redirect('https://goo.gl/maps/o6eFpjAWHQSMY4sK9')} animate={{scale: [1, 1.08, 1, 1.05, 1]}} transition={{duration: 0.3, ease: 'easeInOut', repeat: Infinity, repeatDelay: 3}} style={{cursor: 'pointer'}} whileHover={{scale: 1.03}} whileTap={{scale: 0.96}} src={location} alt="Posizione" />
                    <p>Trovaci sul map!</p>
                </motion.div>
            </div>
            <div className='whereWeAreImages'>
                <motion.figure whileInView={{opacity: [0, 0.6, 1], scale: [0.9, 1.01, 1]}} viewport={{once: true}} transition={{duration: 0.4, delay: 0.4, ease: 'easeOut'}} className='contain'>
                    <img src={pokeHub} alt="" />
                </motion.figure>
                <motion.figure whileInView={{opacity: [0, 0.6, 1], scale: [0.9, 1.01, 1]}} viewport={{once: true}} transition={{duration: 0.4, delay: 0.4, ease: 'easeOut'}} className='contain'>
                    <img src={piazzaLuni} alt="" />
                </motion.figure>
                <motion.figure whileInView={{opacity: [0, 0.6, 1], scale: [0.9, 1.01, 1]}} viewport={{once: true}} transition={{duration: 0.4, delay: 0.4, ease: 'easeOut'}} className='contain'>
                    <img src={tavoli} alt="" />
                </motion.figure>
            </div>
            <div className='whereWeAreFlex'>
                <motion.h2 whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whereWeAreSubtitle'>Consegna a domicilio</motion.h2>
                <motion.p whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whereWeAreText'>Il poke arriva anche a casa tua. Trovaci su:</motion.p>
                <motion.img onClick={() => redirect('https://deliveroo.it/it/menu/Sarzana/sarzana/tata-poke-sarzana/?day=today&geohash=spz4fwnzts8t&time=1200')} style={{cursor: 'pointer'}} whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='deliveroo' src={deliveroo} alt="Deliveroo" />
            </div>
            <div className='whereWeAreFlex'>
                <motion.h2 whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whereWeAreSubtitle2'>Alcuni dei nostri fornitori</motion.h2>
                <div className='whereWeAreFornitores'>
                    <motion.img onClick={() => redirect('https://www.coalvi.it/')} whileInView={{opacity: [0, 1], y: [20, 0]}} viewport={{once: true}} transition={{delay: 0.5, type: 'just'}} src={coalvi} alt="Coalvi" />
                    <motion.img onClick={() => redirect('https://toscopesce.online/')} whileInView={{opacity: [0, 1], y: [20, 0]}} viewport={{once: true}} transition={{delay: 0.6, type: 'just'}} src={toscopesce} alt="Toscopesce" />
                    <motion.img onClick={() => redirect('https://www.agroalimentaresarzanese.it/')} whileInView={{opacity: [0, 1], y: [20, 0]}} viewport={{once: true}} transition={{delay: 0.7, type: 'just'}} src={agroalimentare} alt="Agroalimentare Sarzana" />
                    <motion.img onClick={() => redirect('https://www.lunensis.it/')} whileInView={{opacity: [0, 1], y: [20, 0]}} viewport={{once: true}} transition={{delay: 0.8, type: 'just'}} src={lunensis} alt="Lunensis" />
                </div>
            </div>
        </section>
    )
} 

export default WhereWeAre