import './Social.css'
import whatsapp from '../../assets/whatsapp.png'
import instagram from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'
import instagramWhite from '../../assets/instagramBianco.png'
import facebookWhite from '../../assets/facebookBianco.png'
import { redirect } from '../../functions/Functions'
import { motion } from 'framer-motion'

const Social = ({color}) => {

    if (color === 'white') {
        return(
            <div className="social">
                <img onClick={() => redirect('https://instagram.com/tata_poke?igshid=YmMyMTA2M2Y=')} src={instagramWhite} alt="instagram" />
                <img onClick={() => redirect('https://www.facebook.com/tatapokesarzana')} src={facebookWhite} alt="facebook" />
            </div>
        )
    }else{
        return(
            <motion.div className="social">
                <figure>
                    <img onClick={() => redirect('https://wa.link/7ne17n')} src={whatsapp} alt="Whatsapp" />
                </figure>
                <figure>
                    <img onClick={() => redirect('https://instagram.com/tata_poke?igshid=YmMyMTA2M2Y=')} src={instagram} alt="Instagram" />
                </figure>
                <figure>
                    <img onClick={() => redirect('https://www.facebook.com/tatapokesarzana')} src={facebook} alt="Facebook" />
                </figure>
            </motion.div>
        )
    }

    
}

export default Social