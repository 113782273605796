import './Wine.css'

const Wine = ( {id, name, priceBottle, priceGlass} ) => {
    return(
        <div className='wine'>
            <p className='wineName'>{name}</p>
            <div className='winePrice'>
                <p>{priceGlass}</p>
                <p>{priceBottle}</p>
            </div>
        </div>
    )
}

export default Wine