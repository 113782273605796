import './DrinkSuggestions.css'
import Bevande from '../Bevande/Bevande'
import Drinks from '../Drinks/Drinks'
import { motion } from 'framer-motion'

const DrinkSuggestions = ( {drinks, bevande} ) => {
    return(
        <motion.div initial={{opacity: 0}} animate={{opacity: [0, 1]}} exit={{opacity: 0}} >
            <motion.h2 animate={{opacity: [0, 1], scale: [1.1, 1]}} transition={{duration: 0.4, delay: 0.4}} className='subtitleMenu subtitleMenu576px'>Le nostre bevande</motion.h2>
            <motion.div animate={{opacity: [0, 1], y: [50, 0], scale: [0.95, 1]}} transition={{duration: 0.4, delay: 0.4}} className='drinkSuggestions'>
                <div className='suggestionsContainer'>
                    <h3 className='subtitleMenu subtitleMenu576px'>Bevande</h3>
                    {bevande.map( bev => <Bevande key={bev.id} {...bev}/>)}
                </div>
                <div className='suggestionsContainer'>
                    <h3 className='subtitleMenu subtitleMenu576px'>Drinks</h3>
                    {drinks.map( drink => <Drinks key={drink.id} {...drink}/>)}
                </div>
            </motion.div>
            <p className='comment'>Con l'aggiunta di solo 1,00€ all'aperitivo, serviamo un mini poké</p>
        </motion.div>
    )
}

export default DrinkSuggestions