import './MenuContainer.css'
import MenuSuggestions from '../MenuSuggestions/MenuSuggestions'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import DrinkSuggestions from '../DrinkSuggestions/DrinkSuggestions'
import WineSuggestions from '../WineSuggestions/WineSuggestions'
import IngredientsSuggestions from '../IngredientsSuggestions/IngredientsSuggestions'
import { motion, AnimatePresence } from 'framer-motion'

const suggestions = [{
    id: 1,
    name: 'Via Francigena',
    base: ['Insalata'],
    proteins: ['Tartare de fassona', 'Parmigiano'],
    ingredients:['Melanzane', 'Pomodorini', 'Carote', 'Cipolle caramellate'],
    salse: ['Glassa di aceto balsamico'],
    crunchy: ['Pane carasau'],
    priceSmall: '11,00€',
    priceRegular: '13,00€'
},
{
    id: 2,
    name: 'Tellaro',
    base: ['Farro'],
    proteins: ['Polpo', 'Acciughe'],
    ingredients:['Patate', 'Olive taggiasche', 'Zucchine', 'Pomodorini'],
    salse: ['Pesto'],
    crunchy: ['Taralli'],
    priceSmall: '11,50€',
    priceRegular: '13,50€'
},
{
    id: 3,
    name: 'Versilia',
    base: ['Riso Bianco'],
    proteins: ['Tonno', 'Stracciatella'],
    ingredients:['Avocado', 'Carote', 'Mais', 'Cavolo Viola'],
    salse: ['Salsa teriyaki'],
    crunchy: ['Granella di pistacchio'],
    priceSmall: '11,50€',
    priceRegular: '13,50€'
},
{
    id: 4,
    name: 'Lunigiana',
    base: ['Riso Venere'],
    proteins: ['Pollo a bassa temperatura', 'Caciotta della Lunigiana'],
    ingredients:['Ananas', 'Ceci', 'Zucchine', 'Peperoni'],
    salse: ['Salsa di soia'],
    crunchy: ['Noci'],
    priceSmall: '11,00€',
    priceRegular: '13,00€'
},
{
    id: 5,
    name: 'Montemarcello',
    base: ['Farro'],
    proteins: ['Feta'],
    ingredients:['Pomodorini', 'Ananas', 'Germogli di soia'],
    salse: ['Senape al miele'],
    crunchy: ['Mandorle'],
    priceSmall: '10,50€',
    priceRegular: '12,50€'
},
{
    id: 6,
    name: 'Monte Sagro (Vegan)',
    base: ['Riso'],
    proteins: ['Tofu'],
    ingredients:['Cipolle caramellate', 'Edamame', 'Finocchi', 'Carote'],
    salse: ['Salsa teriyaki'],
    crunchy: ['Triangolini di mais'],
    priceSmall: '10,50€',
    priceRegular: '12,50€'
}]

const drinks = [{
    id: 1,
    name: 'Campari Soda',
    price: '3,50€',
},
{
    id: 2,
    name: 'Spritz Aperol / Campari',
    price: '5,00€',
},
{
    id: 3,
    name: 'Gin Tonic / Vodka Tonic',
    price: '6,00€',
},
{
    id: 4,
    name: 'Gin Lemon / Vodka Lemon',
    price: '6,00€',
},
{
    id: 5,
    name: 'Negroni',
    price: '6,00€',
},
{
    id: 6,
    name: 'Americano',
    price: '6,00€',
},
{
    id: 7,
    name: `Birre Corona / Tennent's / Messina `,
    price: '3,50€',
},
{
    id: 8,
    name: `Birra Ichnusa non filtrata`,
    price: '3,00€',
},
]

const bevande = [{
    id: 1,
    name: 'Aqua Naturale / Frizzante 0,5 lt.',
    price: '1,00€',
},
{
    id: 2,
    name: 'Bibite in lattine Coca Cola / Coca Zero / Fanta / Sprite',
    price: '2,50€',
},
{
    id: 3,
    name: 'Bibite Lurisia Chinotto / Gassosa / Aranciata amara / Limonata',
    price: '3,50€',
},
{
    id: 4,
    name: 'Estathé Pesca / Limone',
    price: '2,50€',
},
{
    id: 5,
    name: 'Red Bull',
    price: '3,50€',
},
{
    id: 6,
    name: 'Cedrata Tassoni',
    price: '3,50€',
},
{
    id: 7,
    name: 'Crodino / Sanbitter',
    price: '3,00€',
},
{
    id: 8,
    name: 'Schweppes',
    price: '2,50€',
},
]

const wine = [{
    id: 1,
    name: 'Groppolo (Il Monticello)',
    priceGlass: '5,00€',
    priceBottle: '18,00€',
},
{
    id: 2,
    name: 'Rupestro (Il Monticello)',
    priceGlass: '5,00€',
    priceBottle: '18,00€',
},
{
    id: 3,
    name: 'Costa Marina (Ottaviano Lambruschi)',
    priceGlass: '6,00€',
    priceBottle: '22,00€',
},
{
    id: 4,
    name: 'Il Maggiore (Ottaviano Lambruschi)',
    priceGlass: '7,00€',
    priceBottle: '24,00€',
},
{
    id: 5,
    name: 'Etichetta Nera (Lunae)',
    priceGlass: '5,00€',
    priceBottle: '19,00€',
},
{
    id: 6,
    name: 'Cavagino (Lunae)',
    priceGlass: '7,00€',
    priceBottle: '22,00€',
},
{
    id: 7,
    name: 'Niccolò V (Lunae)',
    priceGlass: '6,00€',
    priceBottle: '20,00€',
},
{
    id: 8,
    name: 'Vermentino Nero (Terenzuola)',
    priceGlass: '5,00€',
    priceBottle: '20,00€',
},
{
    id: 9,
    name: 'Rivus Major (Cantina 5 Terre)',
    priceGlass: '5,00€',
    priceBottle: '18,00€',
},
{
    id: 10,
    name: 'Franciacorta Brut Anteprima (Bersi Serlini)',
    priceGlass: '7,00€',
    priceBottle: '24,00€',
},
{
    id: 11,
    name: 'Franciacorta Satèn (Bersi Serlini)',
    priceGlass: '8,00€',
    priceBottle: '26,00€',
},
]

const MenuContainer = ({ refMenu }) => {

    const navigate = useNavigate()

    const {menuId} = useParams()

    return(
        <section className="menuContainer" ref={refMenu}>
            <motion.h1 whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='titleMenu'>Menu</motion.h1>
            <div className='buttonsMenu'>
                <motion.div whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}}>
                    <button onClick={() => navigate(`/`)} className='buttonPoke'></button>
                    <p>Poke</p>
                </motion.div>
                <motion.div whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.45, type: 'just'}}>
                    <button onClick={() => navigate(`/menu/drink`)} className='buttonDrink'></button>
                    <p>Drink</p>
                </motion.div>
                <motion.div whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.5, type: 'just'}}>
                    <button onClick={() => navigate(`/menu/wine`)} className='buttonWine'></button>
                    <p>Wine</p>
                </motion.div>
                <motion.div whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.5, type: 'just'}}>
                    <button onClick={() => navigate(`/menu/ingredients`)} className='buttonIngredients'></button>
                    <p>Ingredients</p>
                </motion.div>
            </div>
            <AnimatePresence>
                {
                    menuId === undefined ? (
                        <MenuSuggestions key='suggestions' suggestions={suggestions}/>
                    ) : 
                        menuId === 'drink' ? (
                            <DrinkSuggestions key='drinks' bevande={bevande} drinks={drinks}/>
                        ) : 
                            menuId === 'wine' ? (
                                <WineSuggestions key='wine' wine={wine}/>
                            ) : 
                                <IngredientsSuggestions key='ingredients'/>

                }
            </AnimatePresence>
        </section>
    )
 
}

export default MenuContainer 