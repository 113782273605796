import './PokeImg.css'
import image from '../../assets/poke3.jpg'

const PokeImg = () => {
    return(
        <figure class="snip1033">
            <img src={image} alt="Poke" />
        </figure>
    )
}

export default PokeImg