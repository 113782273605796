import './Navbar.css'
import Logo from '../Logo/Logo'
import Social from '../Social/Social'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const Navbar = ({refDoveSiamo, refLaNostraStoria, refMenu, refHome}) => {

    const [isVisible, setIsVisible] = useState(false)

    const toggleDropdown = () => {
        setIsVisible(!isVisible)
    }

    const scrollTo = (referencia) => {
        window.scrollTo({
            top: referencia.current.offsetTop,
            behavior: 'smooth'
        })
    }

    return(
        <nav className="navbar">
            <Logo/>
            <motion.div className="navbar-buttons" initial={{opacity: 0, y: -40}} animate={{opacity: 1, y:0}} transition={{type: 'spring',duration: 0.8, delay: 0.8}}>
                <button onClick={() => window.location.reload()} className="button">Home</button>
                <button onClick={() => scrollTo(refDoveSiamo)} className="button">Dove Siamo</button>
                <button onClick={() => scrollTo(refMenu)} className="button">Menu</button>
                <button onClick={() => scrollTo(refLaNostraStoria)} className="button">La Nostra Storia</button>
            </motion.div>
            <motion.div initial={{opacity: 0, y: -40}} animate={{opacity: 1, y: 0}} transition={{duration: 0.8, type: 'spring', delay: 1}} className='social-icons'>
                <Social color='green'/>
            </motion.div>
            <span className='burger' onClick={toggleDropdown}></span>
            <AnimatePresence>
            {
                isVisible && (
                    <motion.div animate={{opacity: [0, 1]}} exit={{opacity: [1, 0]}} className='dropdown'>
                        <button onClick={() => scrollTo()} className="button">Home</button>
                        <button onClick={() => scrollTo(refDoveSiamo)} className="button">Dove Siamo</button>
                        <button onClick={() => scrollTo(refMenu)} className="button">Menu</button>
                        <button onClick={() => scrollTo(refLaNostraStoria)} className="button">La Nostra Storia</button>
                        <motion.div initial={{opacity: 0, y: -40}} animate={{opacity: 1, y: 0}} transition={{duration: 0.4, type: 'spring', delay: 0.3}} className='dropdown-social'>
                            <Social color='green'/>
                        </motion.div>
                    </motion.div>
                )
            }
            </AnimatePresence>
        </nav>
    )
}

export default Navbar