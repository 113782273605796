import './WhoWeAre.css'
import luce from '../../assets/luce.jpg'
import notte from '../../assets/giardinoNotte.jpg'
import tantiPoke from '../../assets/tantiPoke.jpg'
import vini from '../../assets/pokeWine.jpg'
import { motion } from 'framer-motion'

const WhoWeAre = ({ refLaNostraStoria }) => {

    return(
        <section className='whoWeAre' ref={refLaNostraStoria}>
            <motion.h1 whileInView={{opacity: [0, 1], y: [40, 0]}} viewport={{once: true}} transition={{delay: 0.4, type: 'just'}} className='whoWeAreTitle'>La nostra storia</motion.h1>
            <div className='whoWeAreBox'>
                <motion.div whileInView={{opacity: [0, 1], x: [-100, 0]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} className='whoWeAreText'>
                    <p>Ciao! Siamo Matteo e Sara, i fondatori di Tata Poké.</p>
                    <p>Il concept di Tata Poké nasce nel 2021, ma la prima vera idea ha radici più lontane. </p>
                    <p>In pieno lockdown, nel 2020, ci rendemmo conto che il lavoro che facevamo non corrispondeva a quello che ci piaceva fare e soprattutto al nostro scopo nella vita. </p>
                </motion.div>
                <motion.img whileInView={{opacity: [0, 1]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} src={luce} alt="Luce" />
            </div>
            <div className='whoWeAreBox reverse'>
                <motion.img whileInView={{opacity: [0, 1]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} src={notte} alt="Giardino" />
                <motion.div whileInView={{opacity: [0, 1], x: [100, 0]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} className='whoWeAreText'>
                    <p>Dopo 10 anni in giro per il mondo, Matteo ha capito che Sarzana era il posto dove voleva tornare a vivere: un vero e proprio ritorno a casa, dalla sua famiglia (e dal suo cane Pippo). </p>
                    <p>Per Sara, nata nel Sud Italia e amante del mare, ma nordica di adozione, non è stato difficile innamorarsi della Lunigiana con i suoi borghi medievali, le colline di vigneti, le Apuane in vista e quel romantico confine tra Liguria e Toscana segnato dalla foce del Magra.</p>
                </motion.div>
            </div>
            <motion.p whileInView={{scaleY: [0, 1]}} viewport={{once: true}} transition={{duration: 0.4, delay: 0.4}} className='whoWeAreSubtitle'>Noi non volevamo semplicemente aprire un Poké, potrebbe essere il nostro motto.</motion.p>
            <div className='whoWeAreBox'>
                <motion.div whileInView={{opacity: [0, 1], x: [-100, 0]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} className='whoWeAreText'>
                    <p>Noi volevamo inventare un concetto di ristorazione nuovo: servire poké all’italiana sfruttando la bontà e la genuinità degli ingredienti che vengono coltivati o prodotti sul territorio.</p>
                    <p>Il nostro menu non ha nulla di esotico: i nostri fornitori sono piccole e medie aziende italiane di giovani come noi. Ci riforniamo ogni mattina all’alba di prodotti freschissimi che raccontano storie bellissime sulla Lunigiana, sulla Liguria e sull’Italia in generale.</p>
                </motion.div>
                <motion.img whileInView={{opacity: [0, 1]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} src={tantiPoke} alt="Poke" />
            </div>
            <div className='whoWeAreBox reverse'>
                <motion.img whileInView={{opacity: [0, 1]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} src={vini} alt="Vini" />
                <motion.div whileInView={{opacity: [0, 1], x: [100, 0]}} viewport={{once: true}} transition={{duration: 0.5, delay: 0.4, type: 'just'}} className='whoWeAreText'>
                    <p>Dalle Olive di Taggia al pesto di Pra, dal farro coltivato in provinciadi Massa Carrara a zucchine, melanzane, cipolle e altre verdure coltivate a meno di 5 chilometri dal centro di Sarzana, siamo sicuri di offrire ogni giorno il meglio per i nostri clienti.</p>
                    <p>Anche le bevande e i vini sono stati selezionati appositamente per un menu Mediterraneo: la carta dei vini propone etichette di cantine delle Cinque Terre e della Lunigiana, insieme alle bevande frizzanti o alle limonate con frutta 100% italiana.</p>
                </motion.div>
            </div>
            <motion.p whileInView={{scaleY: [0, 1]}} viewport={{once: true}} transition={{duration: 0.4, delay: 0.4}} className='whoWeAreSubtitle'>Persino il nome ha un significato: Tata è il soprannome che da sempre Matteo ha dato a Sara.</motion.p>
        </section>
    )
}

export default WhoWeAre