import './MenuPoke.css'
import imgPoke from '../../assets/icon-poke.png'
import { motion } from 'framer-motion'

const MenuPoke = ( {name, id, base, ingredients, proteins, salse, crunchy, priceSmall, priceRegular} ) => {
    return(
        <motion.div animate={{opacity: [0, 1], y: [50, 0], scale: [0.95, 1]}} transition={{duration: 0.2, delay: 0.4}} className="menuPoke">
            <h3 style={{fontSize: 25}}>{name.toUpperCase()}</h3>
            <p className='pokeData'>{base}, {ingredients.join(', ')}, {proteins.join(', ')}, {salse.join(', ')}, {crunchy}</p>
            <div className='size' >
                <div>
                    <img className='small' src={imgPoke} alt="" />
                    <p>Small <br/> <span> {priceSmall} </span> </p>
                </div>
                <div>
                    <img className='regular' src={imgPoke} alt="" />
                    <p>Regular <br/> <span> {priceRegular} </span></p>
                </div>
            </div>
        </motion.div>
    )
}

export default MenuPoke