import './ScrollToTop.css'
import { motion, AnimatePresence } from 'framer-motion'
import { useState, useEffect } from "react"

const ScrollToTop = () => {

    const [showTopBtn, setShowTopBtn] = useState(false)

    useEffect( () => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 400) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        })
    }, [])

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return(
        <AnimatePresence>
            <div>
                { showTopBtn && (
                    
                        <motion.div animate={{opacity: [0, 1], scaleY: [0, 1]}} exit={{opacity: [1, 0], scaleX: [1, 0]}} className="scrollToTop" onClick={goToTop}></motion.div>
                                
                )}
            </div>
        </AnimatePresence>  
    )
}

export default ScrollToTop