import './Presentation.css'
import PokeImg from '../PokeImg/PokeImg'
import PokeCinqueTerre from '../../assets/pokeCinqueTerre.jpg'
import Poke4 from '../../assets/poke4.jpg'
import Poke1 from '../../assets/poke1.jpg'
import Poke2 from '../../assets/poke2.jpg'
import { motion } from 'framer-motion'

const Presentation = ({refHome}) => {

    return(
        <section ref={refHome}>
            <div className="presentation">
                <div>
                    <motion.h1 className="title" initial={{opacity: 0, y: 30}} animate={{opacity: [0, 0.6, 1], y: 0}} transition={{duration: 0.5, delay: 0.1, ease: 'easeOut'}}>Il nostro <span>pokè</span> <br/> è un piatto <br/> che parla <br/> <span className='hawai'>hawaiano.</span> <br/> <span className='italy'>Italiano</span></motion.h1>
                </div>
                <motion.div className="containerImg" initial={{opacity: 0, scale: 0.9}} animate={{opacity: [0, 0.6, 1], scale: [0.9, 1.01, 1]}} transition={{duration: 0.4, delay: 1, ease: 'easeOut'}}>
                    <PokeImg/>
                </motion.div>
            </div>
            <div className='information'>
                <div className='pictures'>
                    <motion.img className='img1' src={PokeCinqueTerre} alt=""  whileInView={{opacity: [0, 0.6, 1], scale: [1.05, 1]}} transition={{delay: 0.5, duration: 0.4}} viewport={{once: true}} />
                    <motion.img className='img2' src={Poke4} alt="" whileInView={{opacity: [0, 0.6, 1], scale: [1.05, 1]}} transition={{delay: 0.7, duration: 0.4}}  viewport={{once: true}} />
                    <motion.img className='img3 none' src={Poke1} alt="" whileInView={{opacity: [0, 0.6, 1], scale: [1.05, 1]}} transition={{delay: 0.5, duration: 0.4}}  viewport={{once: true}} />
                    <motion.img className='img4 none' src={Poke2} alt="" whileInView={{opacity: [0, 0.6, 1], scale: [1.05, 1]}} transition={{delay: 0.7, duration: 0.4}}  viewport={{once: true}} />
                </div>
                <div className='textInfo'>
                    <motion.h2 whileInView={{opacity: [0, 0.6, 1], x: [200, 0]}} transition={{duration: 1, delay: 0.5, type: 'just'}}  viewport={{once: true}}>Vieni a <br/> scoprire la <br/> sorprendente <br/> bontà di un <br/> pokè <br/> all'italiana.</motion.h2>
                </div>
            </div>
        </section>
       
    )
}

export default Presentation