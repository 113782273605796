import './Drinks.css'

const Drinks = ({id, name, price}) => {
    return(
        <div className="drink">
            <p>{name}</p>
            <p>{price}</p>
        </div>
    )
}

export default Drinks