import './Footer.css'
import logo from '../../assets/logo2.png'
import deliveroo from '../../assets/logoDeliveroo.png'
import location from '../../assets/location.png'
import whatsappWhite from '../../assets/whatsappBianco.png'
import clock from '../../assets/clock.png'
import Social from '../Social/Social'
import { redirect } from '../../functions/Functions'

const Footer = () => {
    return(
        <section className='footer'>
            <div className='logoLocation'>
                <img className='logoFooter' src={logo} alt="" />
                <div onClick={() => redirect('https://goo.gl/maps/o6eFpjAWHQSMY4sK9')} className='location'>
                    <img style={{cursor: 'pointer'}} src={location} alt="" />
                    <p style={{cursor: 'pointer'}} >Via Antonio Gramsci 21 <br/> 19038 Sarzana SP</p>
                </div>
            </div>
            <div className='orari'>
                <div className='clock'>
                    <img src={clock} alt="" />
                    <h2>Orari</h2>
                </div>
                <table>
                    <tr>
                        <th>Lunedì</th>
                        <th>11:30 - 14:30</th>
                        <th>18:30 - 21:00</th>
                    </tr>
                    <tr>
                        <th>Martedì</th>
                        <th>11:30 - 14:30</th>
                        <th style={{textAlign: 'center'}}>-</th>
                    </tr>
                    <tr>
                        <th>Mercoledì</th>
                        <th>11:30 - 14:30</th>
                        <th>18:00 - 21:00</th>
                    </tr>
                    <tr>
                        <th>Giovedì</th>
                        <th>11:30 - 14:30</th>
                        <th>18:30 - 21:30</th>
                    </tr>
                    <tr>
                        <th>Venerdì</th>
                        <th>11:30 - 14:30</th>
                        <th>18:30 - 22:00</th>
                    </tr>
                    <tr>
                        <th>Sabato</th>
                        <th>11:30 - 14:30</th>
                        <th>18:00 - 22:00</th>
                    </tr>
                    <tr>
                        <th>Domenica</th>
                        <th>12:00 - 14:30</th>
                        <th style={{textAlign: 'center'}}>-</th>
                    </tr>
                </table>
            </div>
            <div className='delivery top margin'>
                <div className='delivery deliveroo2'>
                    <h2>Consegna a domicilio</h2>
                    <img onClick={() => redirect('https://deliveroo.it/it/menu/Sarzana/sarzana/tata-poke-sarzana/?day=today&geohash=spz4fwnzts8t&time=1200')} style={{cursor: 'pointer'}} src={deliveroo} alt="" />
                </div>
                <div className='delivery follow'>
                    <h2>Contatti</h2>
                    <img src={whatsappWhite} alt="" />
                </div>
                <div className='delivery follow'>
                    <h2>Follow Us</h2>
                    <Social color='white'/>
                </div>
            </div>
            <p className='copyright'>© 2022-23 Tata Poke</p>
        </section>
    )
}

export default Footer