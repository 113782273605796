import './MenuSuggestions.css'
import MenuPoke from '../MenuPoke/MenuPoke'
import { motion } from 'framer-motion'

const MenuSuggestions = ( {suggestions} ) => {
    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <motion.h2 animate={{opacity: [0, 1], scale: [1.1, 1]}} transition={{duration: 0.4, delay: 0.4}} className='subtitleMenu subtitleMenu576px'>Le nostre proposte</motion.h2>
            <div className='menuSuggestions'>
                {suggestions.map( poke => <MenuPoke key={poke.id} {...poke}/>)}
            </div>
        </motion.div>
    )
}

export default MenuSuggestions