import logo from '../../assets/logo.png'
import './Logo.css'
import { motion } from 'framer-motion'

const Logo = () => {
    return(
        <motion.div initial={{opacity: 0, y: -60}} animate={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', delay: 0.6}}>
            <img className="logo" src={logo} alt="Tata Poke" />
        </motion.div>
    )
}

export default Logo