import './IngredientsSuggestions.css'
import { motion } from 'framer-motion'

const IngredientsSuggestions = () => {
    return(
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='winesFlex'>
            <motion.h2 animate={{opacity: [0, 1], scale: [1.1, 1]}} transition={{duration: 0.4, delay: 0.4}} className='subtitleMenu subtitleMenu576px'>Componilo <br/> <span className='italy'>con i nostri ingredienti</span></motion.h2>
            <motion.div animate={{opacity: [0, 1], y: [50, 0], scale: [0.95, 1]}} transition={{duration: 0.4, delay: 0.4}} className='ingredientiContainer'>
                <h3 className='subtitleMenu subtitleMenu576px'>Scelgi la misura</h3>
                <div className='misuraContainer'>
                    <div className='misura'>
                        <div className='price underline'>
                            <h4 >Small</h4>
                            <p>11,50 €</p>
                        </div>
                        <p>1 Base / 1 Proteina / 3 Ingredienti <br/> 1 Crunchy / 1 Salsa</p>
                    </div>
                    <div className='misura'>
                        <div className='price underline'>
                            <h4>Regular</h4>
                            <p>14,00 €</p>
                        </div>
                        <p>1 Base / 2 Proteina / 4 Ingredienti <br/> 1 Crunchy / 2 Salsa</p>
                    </div>
                </div>
                <div className='scelteContainer'>
                    <div className='scelte'>
                        <div className='boxScelte'>
                            <h4 className='titleScelte underline'>Base</h4>
                            <ul className='textScelte'>
                                <li>Riso Basmati</li>
                                <li>Riso Venere</li>
                                <li>Farro</li>
                                <li>Insalata</li>
                            </ul>
                            <p className='cursive'>extra +1,50 €</p>
                        </div>
                        <div className='boxScelte'>
                            <h4 className='titleScelte underline'>Proteine</h4>
                            <ul className='textScelte'>
                                <li>Salmone marinato</li>
                                <li>Tonno scottato</li>
                                <li>Gamberi</li>
                                <li>Polpo</li>
                                <li>Acciughe</li>
                                <li>Pollo a bassa temperatura</li>
                                <li>Tartare di fassona</li>
                                <li>Salame</li>
                                <li>Burrata</li>
                                <li>Caciotta della Lunigiana</li>
                                <li>Feta</li>
                                <li>Stracciatella</li>
                                <li>Tofu</li>
                            </ul>
                            <p className='cursive'>extra +2,50 €</p>
                        </div>
                    </div>
                    <div className='scelte'>
                        <div className='boxScelte'>
                            <h4 className='titleScelte underline'>Ingredienti</h4>
                            <ul className='textScelte'>
                                <li>Ananas</li>
                                <li>Avocado</li>
                                <li>Capperi</li>
                                <li>Cavolo Viola</li>
                                <li>Ceci</li>
                                <li>Cipolle Caramellate</li>
                                <li>Carote</li>
                                <li>Edamame</li>
                                <li>Mais</li>
                                <li>Melanzane grigliate</li>
                                <li>Fagioli</li>
                                <li>Patate</li>
                                <li>Peperoni</li>
                                <li>Pomodorini</li>
                                <li>Germogli di soia</li>
                                <li>Finocchio</li>
                                <li>Olive taggiasche</li>
                                <li>Zucchine</li>
                            </ul>
                            <p className='cursive'>extra +1,00 €</p>
                       </div>
                    </div>
                    <div className='scelte'>
                        <div className='boxScelte'>
                            <h4 className='titleScelte underline'>Salse</h4>
                            <ul className='textScelte'>
                                <li>Salsa Teriyaki</li>
                                <li>Soia</li>
                                <li>Salsa rosa</li>
                                <li>Senape al miele</li>
                                <li>Olio EVO</li>
                                <li>Pesto</li>
                                <li>Maionese al basilico</li>
                                <li>Glassa aceto balsamico</li>
                                <li>Salsa yogurt</li>
                            </ul>
                            <p className='cursive'>extra +0,50 €</p>
                        </div>
                        <div className='boxScelte'>
                            <h4 className='titleScelte underline'>Crunchy</h4>
                            <ul className='textScelte'>
                                <li>Granella pistacchio</li>
                                <li>Noci</li>
                                <li>Mandorle</li>
                                <li>Nachos</li> 
                                <li>Semi di sesamo</li>
                                <li>Semi di lino</li>
                                <li>Taralli</li>
                                <li>Pane carasau</li>
                                <li>Triangolini di mais</li>
                                <li>Cipolle fritte</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default IngredientsSuggestions